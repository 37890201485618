import { Identification } from '../model'
import { ConfigResult, FrontendError } from './Model'

export type DroneEndpoints = typeof routes & {
  config: { path: undefined; body: undefined; result: ConfigResult }
  reportError: { path: undefined; body: FrontendError; result: undefined }
  sessionInfo: { path: undefined; body: undefined; result: Identification.State }
}

export const routes = {
  config: { method: 'get', pattern: '/config', security: 'none' },
  reportError: { method: 'post', pattern: '/errors', security: 'none' },
  sessionInfo: { method: 'get', pattern: '/session-info', security: 'none' },
} as const

export type EndpointName = keyof typeof routes

export const endpointNames = Object.keys(routes) as EndpointName[]

export type DroneRoutes = typeof routes
