import { InteractionType } from '@azure/msal-browser'
import { useMsalAuthentication } from '@azure/msal-react'
import { User } from '@microsoft/microsoft-graph-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OrgMembersResponse } from 'traficom-registry-shared/out/model/UserProfile'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { api } from '../../services/api'
import { FieldGroup } from '../../ui-user-mgmt/form'
import LoadingSpinner from '../../ui-user-mgmt/loading/loading-spinner'
import { Comp } from '../../utils/component'
import { getRedirectRequest } from './MsalInstance'

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 6px 6px rgba(0, 0, 0, 0.23);
`

export const CardContent = styled.div`
  padding: 30px 30px 40px 32px;
  width: 100%;
`

const SwitchArea = styled.div`
  display: flex;
  flex-direction: row;
`

const NameInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const Name = styled.div`
  font-size: 18px;
  font-weight: 700;
`

const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 28px;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const DotContainer = styled.div<DotMenuProps>`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.openMenu ? '#1777f8;' : '#fff;')}
  border: 2px solid ${props => (props.openMenu ? '#1777f8;' : '#fff;')}
  border-radius: 8px;
  cursor: pointer;
`

const DotMenu = styled.div`
  display: flex;
  flex-direction: column;
`

export type DotMenuProps = {
  openMenu: boolean
}

const Dot = styled.div<DotMenuProps>`
  width: 4px;
  height: 4px;
  margin: 2px;
  background: ${props => (props.openMenu ? '#fff;' : '#000;')}
  border-radius: 50%;
`
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownMenu = styled.div<DotMenuProps>`
  display: ${props => (props.openMenu ? 'block;' : 'none;')}
  position: absolute;
  right: 0;
  top: 6px;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 6px 6px rgba(0, 0, 0, 0.23);
`

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 24px;
  min-width: 180px;
`

const MenuItem = styled.a`
  color: black;
  text-decoration: none;
  display: block;
  padding: 8px 24px;

  &:hover {
    background-color: #eaeaea;
    cursor: pointer;
  }
`

const CheckBoxWrapper = styled.div`
  position: relative;
`

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 2px;
  background: #bebebe;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 2px;
    width: 18px;
    height: 18px;
    margin: 4px;
    background: #ffffff;
    transition: 0.2s;
  }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 2px;
  width: 42px;
  height: 26px;

  &:checked + ${CheckBoxLabel} {
    background: #1777f8;

    &::after {
      content: '';
      display: block;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      margin-left: 20px;
      transition: 0.2s;
    }
  }

  &:disabled + ${CheckBoxLabel} {
    background: #9696aa;
    cursor: default;

    &::after {
      content: '';
      display: block;
      border-radius: 2px;
      width: 18px;
      height: 18px;
      transition: 0.2s;
      background: #cdcdd7;
    }
  }
`

const AdminRoleLabel = styled.label`
  padding-left: 10px;
  padding-right: 18px;
  vertical-align: top;
`

export const AdditionalInfo = styled.p`
  margin-top: 3px;
  margin-bottom: 0px;
  color: #aaa;
  font-style: italic;
`

/* eslint-disable */

export const GroupMembers: Comp<{
  businessId: string
  orgAdminIds: string[]
  setOrgAdminIds: Function
  token: string
  adminOid: string
}> = props => {
  const [grpMembers, setGrpMembers] = useStateWithCallbackLazy<(OrgMembersResponse & { loading?: boolean })[]>([])
  const [memberId, setMemberId] = useState<string | undefined>('')
  const { t } = useTranslation()

  const tokenRequest = getRedirectRequest('/#/org-admin')
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, tokenRequest)

  useEffect(() => {
    const fetchData = async () => {
      if (props.token) {
        const members = await api.getOrgMembers({ businessId: props.businessId }, { accessToken: props.token })
        if (members.success) {
          const sortkey = (user: User) =>
            user.surname && user.givenName ? user.surname + ' ' + user.givenName : (user.displayName as string)
          const sorted = members.data
          sorted.sort((a, b) => sortkey(a.user).localeCompare(sortkey(b.user)))

          setGrpMembers(sorted, () => {})
        } else {
          console.error(members) // TODO
        }
      }
    }

    if (result !== null) {
      fetchData().catch(console.error)
    }
  }, [error, result])

  const indicateUserProgress = async (user: OrgMembersResponse, performOp: Promise<any>) => {
    setGrpMembers(
      grpMembers.map(m => ({ ...m, loading: m.user.id == user.user.id ? true : false })),
      () => {
        performOp.then(() => {
          setGrpMembers(
            grpMembers.map(m => ({ ...m, loading: false })),
            () => {},
          )
        })
      },
    )
  }

  const toggleOrgAdminRole = async (user: OrgMembersResponse) => {
    const usr = grpMembers.filter(u => u.user.id == user.user.id)[0]

    const doOp = async () => {
      if (memberIsAdmin(usr.user.id)) {
        await api.removeOrgAdmin({ userId: user.user.id, businessId: props.businessId }, { accessToken: props.token })
        props.setOrgAdminIds(props.orgAdminIds.filter(i => i !== usr.user.id))
      } else {
        await api.addOrgAdmin({ userId: user.user.id, businessId: props.businessId }, { accessToken: props.token })
        props.setOrgAdminIds([...props.orgAdminIds, usr.user.id])
      }
    }

    await indicateUserProgress(user, doOp())
  }

  const toggleGroupMembership = async (user: OrgMembersResponse & { loading?: boolean }, groupId: string) => {
    const usr = grpMembers.filter(u => u.user.id == user.user.id)[0]

    setGrpMembers(
      grpMembers.map(m => ({ ...m, loading: m.user.id == user.user.id ? true : false })),
      () => {
        const group = usr.groups.filter(g => g.groupId == groupId)[0]

        const prom = group.member
          ? api.removeUserFromGroup(
              { userId: user.user.id, groupId: groupId, businessId: props.businessId },
              { accessToken: props.token },
            )
          : api.addUserToGroup(
              { userId: user.user.id, groupId: groupId, businessId: props.businessId },
              { accessToken: props.token },
            )

        prom
          .then(() => {
            const updatedMembers = grpMembers.map(m =>
              m.user.id == user.user.id
                ? {
                    user: m.user,
                    groups: usr.groups.map(g =>
                      g.groupId == groupId
                        ? {
                            groupId: g.groupId,
                            groupDisplayName: g.groupDisplayName,
                            member: !g.member,
                            enabled: g.enabled,
                          }
                        : g,
                    ),
                  }
                : m,
            ) as OrgMembersResponse[]

            setGrpMembers(updatedMembers, () => {})
          })
          .catch(error => {
            setGrpMembers(
              grpMembers.map(m => ({ ...m, loading: false })),
              () => {},
            )
          })
      },
    )
  }

  const memberIsAdmin = (memberId: string | undefined) => {
    return memberId ? props.orgAdminIds.includes(memberId) : false
  }

  return (
    <FieldGroup title={t('user_management:organization_members')}>
      {grpMembers.length > 0 && (
        <>
          {grpMembers.map(m => (
            <ListItem key={m.user.id}>
              <CardContent>
                <UpperSection>
                  <NameInfo>
                    <Name>{m.user.displayName}</Name>
                    <p>{m.user.mail}</p>
                  </NameInfo>
                  <MenuContainer>
                    <DotContainer
                      openMenu={memberId === m.user.id}
                      onClick={() => setMemberId(memberId === m.user.id ? '' : m.user.id)}
                    >
                      <DotMenu>
                        <Dot openMenu={memberId === m.user.id} />
                        <Dot openMenu={memberId === m.user.id} />
                        <Dot openMenu={memberId === m.user.id} />
                      </DotMenu>
                    </DotContainer>
                    <DropdownContainer>
                      <DropdownMenu openMenu={memberId === m.user.id}>
                        <MenuItemContainer>
                          <MenuItem
                            onClick={async () => {
                              if (window.confirm(t('user_management:confirm_remove_org_member'))) {
                                const result = await api.deleteOrgMember(
                                  { userId: m.user.id, businessId: props.businessId },
                                  { accessToken: props.token },
                                )
                                if (result.success) {
                                  window.location.reload()
                                } else {
                                  console.error() // TODO
                                }
                              }
                            }}
                          >
                            {t('user_management:remove_member')}
                          </MenuItem>
                        </MenuItemContainer>
                      </DropdownMenu>
                    </DropdownContainer>
                  </MenuContainer>
                </UpperSection>

                <LoadingSpinner isLoading={m.loading}>
                  <SwitchArea>
                    <CheckBoxWrapper>
                      <CheckBox
                        id={m.user.id}
                        type="checkbox"
                        checked={memberIsAdmin(m.user.id)}
                        disabled={props.adminOid === m.user.id}
                        onChange={async () => await toggleOrgAdminRole(m)}
                      />
                      <CheckBoxLabel htmlFor={m.user.id} />
                      <AdminRoleLabel htmlFor={m.user.id}>{t('user_management:admin_role')}</AdminRoleLabel>
                    </CheckBoxWrapper>

                    {m.groups.map(g => (
                      <CheckBoxWrapper key={m.user.id + g.groupId}>
                        <CheckBox
                          id={m.user.id + g.groupId}
                          type="checkbox"
                          checked={g.member}
                          disabled={!g.enabled}
                          onChange={async () => await toggleGroupMembership(m, g.groupId)}
                        />
                        <CheckBoxLabel htmlFor={m.user.id + g.groupId} />
                        <AdminRoleLabel htmlFor={m.user.id + g.groupId}>{g.groupDisplayName}</AdminRoleLabel>
                      </CheckBoxWrapper>
                    ))}
                  </SwitchArea>
                  {props.adminOid === m.user.id && (
                    <AdditionalInfo>{t('user_management:admin_role_change_denied')}</AdditionalInfo>
                  )}
                  {m.groups
                    .filter(g => !g.enabled)
                    .map(g => (
                      <AdditionalInfo key={'ai' + m.user.id + g.groupId}>
                        {t('user_management:org_group_admin_expired', { groupName: g.groupDisplayName })}
                      </AdditionalInfo>
                    ))}
                </LoadingSpinner>
              </CardContent>
            </ListItem>
          ))}
        </>
      )}
    </FieldGroup>
  )
}
